import React, { useState } from "react";
import { Carousel } from "antd";
const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const MyCarousel = ({ screen }) => {
  const [dotPosition, setDotPosition] = useState("top");
  const handlePositionChange = ({ target: { value } }) => {
    setDotPosition(value);
  };
  console.log(screen);
  return (
    <Carousel dotPosition={dotPosition} autoplay>
      {screen.map((v, k) => (
        <div key={k}>
          <img src={v} alt="project_screen" style={{ width: "100%" }} />
        </div>
      ))}
    </Carousel>
  );
};
export default MyCarousel;
