import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const NavBar = () => {
  const pathName = window.location.pathname;
  const [currentKey, setCurrentKey] = useState(pathName);
  const navigate = useNavigate();
  const _handleMenuClick = (e) => {
    navigate(e.key);
    setCurrentKey(e.key);
  };

  useEffect(() => {
    currentKey === "/" && setCurrentKey("/home");
  }, []);
  const { Header } = Layout;
  return (
    <Layout style={{ backgroundColor: "transparent", alignItems: "end" }}>
      <Header style={{ backgroundColor: "transparent" }}>
        <Menu
          style={{ backgroundColor: "transparent", color: "white" }}
          selectedKeys={[currentKey]}
          onClick={_handleMenuClick}
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={pages.map((v, k) => {
            return { k, label: v.name, key: v.key };
          })}
        />
      </Header>
    </Layout>
  );
};
const pages = [
  { name: "Home", key: "/home" },
  { name: "Education", key: "/education" },
  { name: "Skills", key: "/skills" },
  { name: "Projects", key: "/projects" },
  // { name: "Experience", key: "/experience" },
];
