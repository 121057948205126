import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Color } from "../config/config.color";
import "./style.css";
const Education = () => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
        }}
        className="header"
      >
        <i className="fa fa-graduation-cap title-icon" />
        EDUCATION
      </div>
      <VerticalTimeline>
        {data.map((v, k) => (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: Color.paleBule, color: "#fff" }}
            contentArrowStyle={arrowStyle}
            date={v.duration}
            iconStyle={iconStyle}
            icon={<GraduationIcon />}
            key={k}
          >
            <div className="vertical-timeline-element-title title">
              {v.name}
            </div>
            <span className="vertical-timeline-element-subtitle subtitle">
              {v.institute}
            </span>
            <p className="text-thin">{v.detail}</p>
          </VerticalTimelineElement>
        ))}
        <VerticalTimelineElement
          iconStyle={{ background: Color.paleBule, color: Color.lightBlue }}
          icon={
            <i
              className="fa fa-star fa-2x timeline-icon"
              style={{ marginTop: "22%", marginLeft: "20%" }}
            />
          }
        />
      </VerticalTimeline>
    </>
  );
};

export default Education;

const GraduationIcon = () => {
  return (
    <i
      className="fa fa-graduation-cap fa-2x timeline-icon"
      style={{ marginTop: "20%", marginLeft: "18%" }}
    />
  );
};

const iconStyle = {
  background: Color.darkBlue,
  color: "#fff",
};

const arrowStyle = {
  borderRight: "7px solid  #111A4E",
};

const data = [
  {
    duration: "2018- 2019",
    name: "Level 5 Diploma in Computing",
    institute: "NCC Education",
    detail:
      "Learned and practice Agile Development, Analysis, Design and Implementation, Computing Project, Database Design and Development projects",
  },
  {
    duration: "2014 - 2018",
    name: "B.A (English)",
    institute: "Mandalay University of Distance Education",
    detail:
      "Graduated Bachelor of Arts in English language from Mandalay University of Distance Education.",
  },
  {
    duration: "2014-2016",
    name: "Post Graduated Diploma in English",
    institute: "MUFL",
    detail:
      "Post Graduated Diploma in English language from Mandalay University of Foreign Language.",
  },
  {
    duration: "2014 - 2015",
    name: "Diploma In IT",
    institute: "KMD Institute",
    detail:
      "Developing static and dynamic websites and window applications by using vb.net, HTML, CSS, Java script and Microsoft SQL.",
  },
];
