import { Modal } from "antd";
import { useState } from "react";
import MyCarousel from "../animation/carousel";
import { Project } from "../components";
import { ProjectData } from "../constant/project";

const ProjectPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardIndex, setCardIndex] = useState(null);

  const showModal = (index) => {
    setIsModalOpen(true);
    setCardIndex(index);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCardIndex(null);
  };
  return (
    <>
      <Project showModal={showModal} />
      <ProjectModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        cardIndex={cardIndex}
      />
    </>
  );
};

export default ProjectPage;

const ProjectModal = ({ isModalOpen, handleCancel, cardIndex }) => {
  console.log(cardIndex);
  return (
    <Modal
      footer={null}
      title="Project Preview"
      open={isModalOpen}
      bodyStyle={{ color: "white !important" }}
      width={"90%"}
      style={{ top: 0 }}
      onCancel={handleCancel}
    >
      {cardIndex !== null && (
        <MyCarousel screen={ProjectData[cardIndex].screen} />
      )}
    </Modal>
  );
};
