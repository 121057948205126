import { motion } from "framer-motion";
import { useRef } from "react";
import { useFollowPointer } from "./use-flow-pointer.ts";

export const Motion1 = () => {
  const ref = useRef(null);
  const { x, y } = useFollowPointer(ref);
  return (
      <motion.div
        ref={ref}
        style={{
          width: "20%",
          zIndex: -1,
          borderRadius: "100%",
          aspectRatio: 1,
          
          backgroundImage:"radial-gradient(circle, #111A4E, #032535)",
          // background: "#111A4E",
          opacity: 0.2,
          position: "absolute",
        }}
        animate={{ x, y }}
        transition={{
          type: "spring",
          damping: 3,
          stiffness: 50,
          restDelta: 0.001,
        }}
      />
  );
};
