import React, { useState } from "react";
import { Col, Row } from "antd";
import { Color } from "../config/config.color";
import { MyProgressBar } from "../animation/progressBar";
const Skill = () => {
  return (
    <>
      <div style={{ textAlign: "center" }} className="header">
        <i className="fa fa-laptop-code title-icon" />
        SKILLS
      </div>
      {/* <div style={{ height: 800 }}>
        <SkillChart data={data} />
      </div> */}
      {/* <ImageCard/> */}
      <Row gutter={[20, 20]} style={{ margin: 0 }}>
        {skill.map((v, k) => (
          v.type!=="serverSide" && <Col xs={24} md={12} lg={8}>
            <SkillCard title={v.title} language={v.language} />
            {v.type === "backEnd" && <SkillCard title={skill[k+2].title} language={skill[k+2].language} />}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Skill;

const SkillCard = ({ title, language }) => {
  return (
    <div
      style={{
        background: `linear-gradient(315deg, ${Color.paleBule},${Color.darkBlue})`,
        // background: Color.paleBule,
        borderRadius: 10,
        padding: 20,
        border: "1px solid rgb(40,40,40)",
        textAlign: "center",
        marginTop:title==="Deployment & Server Side"?16:0
      }}
    >
      <div className="title">{title}</div>
      {language.map((v, k) => (
        <Row align="middle" style={{ margin: 0, paddingBottom: 10 }}>
          <Col xs={2} sm={2} md={2} style={{ textAlign: "center" }}>
            <div
              style={{
                maxWidth: 40,
                aspectRatio: 1,
                background: "white",
                borderRadius: 5
              }}
            >
              <img src={v.imgURL} width="80%" style={{ marginTop: "8%" }} />
            </div>
          </Col>
          <Col
            xs={8}
            sm={8}
            md={6}
            style={{ textAlign: "left", paddingLeft: 5 }}
          >
            {v.name}
          </Col>
          <Col xs={14} sm={14} md={16}>
            <MyProgressBar percent={v.level} />
          </Col>
        </Row>
      ))}
    </div>
  );
};

const skill = [
  {
    type: "frontEnd",
    title: "Front-end",
    language: [
      {
        name: "HTML",
        imgURL: "./icon/html.png",
        level: 88,
      },
      {
        name: "CSS",
        imgURL: "./icon/css.png",
        level: 88,
      },
      {
        name: "Javascript",
        imgURL: "./icon/javascript.png",
        level: 80,
      },
      {
        name: "Typescript",
        imgURL: "./icon/typescript.png",
        level: 75,
      },
      {
        name: "React JS",
        imgURL: "./icon/reactjs.png",
        level: 80,
      },
      {
        name: "React Native",
        imgURL: "./icon/reactnative.png",
        level: 50,
      },
      {
        name: "Redux",
        imgURL: "./icon/redux.png",
        level: 75,
      },
      {
        name: "MobX",
        imgURL: "./icon/mobx.png",
        level: 75,
      },
      {
        name: "Bootstrap",
        imgURL: "./icon/bootstrap.png",
        level: 80,
      },
      {
        name: "Ant Design",
        imgURL: "./icon/antdesign.png",
        level: 65,
      },
      {
        name: "SASS",
        imgURL: "./icon/sass.png",
        level: 65,
      },
      {
        name: "Tailwind",
        imgURL: "./icon/tailwind.png",
        level: 65,
      }
    ]
  },
  {
    type: "backEnd",
    title: "Back-end",
    language: [
      {
        name: "Node JS",
        imgURL: "./icon/nodejs.png",
        level: 80,
      },
      {
        name: "MySQL",
        imgURL: "./icon/mysql.png",
        level: 80,
      },
      {
        name: "mongoDB",
        imgURL: "./icon/mongodb.png",
        level: 65,
      },
      {
        name: "Firebase",
        imgURL: "./icon/firebase.png",
        level: 65,
      }
    ]
  },
  {
    type: "Design",
    title: "UI/UX",
    language: [
      {
        name: "Figma",
        imgURL: "./icon/figma.png",
        level: 80,
      },
      {
        name: "Adobe XD",
        imgURL: "./icon/xd.png",
        level: 70,
      },
      {
        name: "Adobe Photoshop",
        imgURL: "./icon/photoshop.png",
        level: 65,
      }
    ]
  },
  {
    type: "serverSide",
    title: "Deployment & Server Side",
    language: [
      {
        name: "Oracle Cloud Infrasturcture",
        imgURL: "./icon/oci.png",
        level: 50,
      },
      {
        name: "Git",
        imgURL: "./icon/git.png",
        level: 65,
      },
      {
        name: "Docker",
        imgURL: "./icon/docker.webp",
        level: 60,
      },
      {
        name: "Jest",
        imgURL: "./icon/jest.jpeg",
        level: 70,
      }
    ]
  }
];
