import { Modal } from "antd";
import { motion } from "framer-motion";
import { useState } from "react";
import { Motion1 } from "../animation/motion";
import { Home } from "../components";
import { ContactForm } from "../components/contactForm";

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <motion.div
      className="container text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2.5 }}
      style={{ position: "relative", overflow: "auto", height: "90vh" }}
    >
      {/* <Motion1 /> */}
      <Home showModal={showModal} />
      <ContactModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </motion.div>
  );
};

export default HomePage;

const ContactModal = ({ isModalOpen, handleCancel }) => {
  return (
    <Modal
      footer={null}
      onCancel={handleCancel}
      title="CONTACT ME"
      open={isModalOpen}
      bodyStyle={{ color: "white !important" }}
    >
      <ContactForm />
    </Modal>
  );
};
