export const ProjectData = [
  {
    title: "Venue Survey App",
    description: "The development of a professional React Native application dedicated to facilitating a toilet feedback survey service. Aims to streamline feedback collection and analysis for public toilet facilities, enhancing user experience and management efficiency.",
    detail:
      "Automated feedback system for toilet in Singapore.",
    imgURL: "./screen/venuesurvey/2.png",
    projectURL: null,
    languages: ["reactjs", "javascript", "nodejs", "mysql"],
    screen: [
      "./screen/venuesurvey/1.png",
      "./screen/venuesurvey/2.png",
      "./screen/venuesurvey/3.png",
      "./screen/venuesurvey/4.png",
      "./screen/venuesurvey/5.png"
    ],
    online: true,
  },
  {
    title: "UNDP",
    description:
      "Sustainable Development Goals and Digital Maturity Assessment Survey Platform",
    detail:
      "Created Sustainable Development Goal targets monitoring dashboard and Digital Maturity Assessment survey platform.",
    imgURL: "./screen/undp/1.png",
    screen: [
      "./screen/undp/1.png",
      "./screen/undp/2.png",
      "./screen/undp/3.png",
      "./screen/undp/4.png",
      "./screen/undp/5.png",
    ],
    projectURL: "http://172.104.40.242:7878",
    languages: ["reactjs", "nodejs", "mysql", "bootstrap"],
    online: true,
  },
  {
    title: "CO₂ Tracking and Computing",
    description:
      "Tracking on carbon emission by vehicles in daily, monthly or yearly.",
    detail:
      "Analyse vehicle sensor raw data and calculate carbon emission including carbon tracking and offset of clients.",
    imgURL: "./screen/co2delete/1.png",
    screen: [
      "./screen/co2delete/1.png",
      "./screen/co2delete/2.png",
      "./screen/co2delete/3.png",
      "./screen/co2delete/4.png",
    ],
    projectURL: "https://www.co2delete.com",
    languages: ["reactjs", "nodejs", "mysql", "redux"],
    online: true,
  },
  {
    title: "Preliminary Assessment Survey",
    description:
      "Intelligent monitoring and optimization platform for Chiller, Pump, and Cooling Tower systems.",
    detail:
      "Saving and reporting records of surveys answered by users for each building and equipment parameters.",
    imgURL: "./screen/pea/1.png",
    screen: ["./screen/pea/1.png", "./screen/pea/2.png", "./screen/pea/3.png"],
    projectURL: "http://172.105.123.57:6767",
    languages: ["reactjs", "nodejs", "mysql", "css"],
    online: true,
  },
  {
    title: "JARVIS",
    description: "Designing a centralized dashboard for administrators and user levels to monitor device communications in real time.",
    detail:
      "An indoor positioning platform that grants users the ability to monitor device status and alert. Keep track of IoT sensor device signals, via JARVIS platform.",
    imgURL: "./screen/jarvis/1.png",
    projectURL: "https://www.viatick.com/jarvis",
    languages: ["reactjs", "nodejs", "mysql", "typescript"],
    screen: [
      "./screen/jarvis/1.png",
      "./screen/jarvis/2.png",
      "./screen/jarvis/3.png",
      "./screen/jarvis/4.png",
      "./screen/jarvis/5.png",
    ],
    online: true,
  },
  {
    title: "Carbon Abatement",
    description:
      "Enviornmental survey platforms and visualized dashboard for Carbon Tracking and Reduction.",
    detail:
      "Created Sustainable Development Goal targets monitoring dashboard and Digital Maturity Assessment survey platform.",
    imgURL: "./screen/co2rap/1.png",
    screen: [
      "./screen/co2rap/1.png",
      "./screen/co2rap/2.png",
      "./screen/co2rap/3.png",
      "./screen/co2rap/4.png",
      "./screen/co2rap/5.png",
    ],
    projectURL: "https://www.co2rap.com",
    languages: ["reactjs", "nodejs", "mongodb", "redux"],
    online: true,
  },
  {
    title: "Kislev Transportation",
    description: "An e-commerce web page for transportation devices.",
    detail:
      "Created Sustainable Development Goal targets monitoring dashboard and Digital Maturity Assessment survey platform.",
    imgURL: "./screen/assignment/1.png",
    // projectURL: "http://139.162.42.237:1339",
    languages: ["reactjs", "html", "css"],
    online: true,
  },
  {
    title: "Footware",
    description: "Creating profiles of potential users such as fashion enthusiasts, athletes, or casual shoppers to tailor the UI accordingly.",
    detail: "An e-commerce platform for the sneaker shop.",
    imgURL: "./screen/shoes/1.png",
    // projectURL: "http://139.162.42.237:1338",
    languages: ["reactjs", "html", "css"],
    online: true,
  },
  {
    title: "Acbel Solar",
    description: "Creating an intuitive dashboard interface to display real-time and historical data trends, showcasing input-output correlations.",
    detail:
      "Created Sustainable Development Goal targets monitoring dashboard and Digital Maturity Assessment survey platform.",
    imgURL: "./screen/acbel/1.png",
    projectURL: "https://www.acbel.com.tw",
    languages: ["ajax", "jquery", "bootstrap"],
    online: true,
  },
  {
    title: "NYEIN Hospital",
    description: "Medical and surgical services and the supporting laboratories, equipment and personnel that make up the medical and surgical mission of a hospital or hospital system.",
    detail:
      "Medical and surgical services and the supporting laboratories, equipment and personnel that make up the medical and surgical mission of a hospital or hospital system.",
    imgURL: "./screen/nyein/1.png",
    projectURL: "https://www.nyeinhospitalmandalay.com/",
    languages: ["reactjs", "html", "bootstrap"],
    screen: [
      "./screen/nyein/1.png",
      "./screen/nyein/2.png"
    ],
    online: true,
  }
];
