import "./style.css";
import { motion } from "framer-motion";
import { Button, Carousel, Col, Row, Space } from "antd";
import { Color } from "../config/config.color";
import { ProjectData } from "../constant/project";

const Project = ({ showModal }) => {
  return (
    <>
      <motion.div
        style={{
          textAlign: "center",
        }}
        className="header"
      >
        <i className="fa fa-diagram-project title-icon" />
        PROJECTS
      </motion.div>
      <Row gutter={[30, 30]} justify="center" style={{ margin: "20px 10px" }}>

        {ProjectData.map((v, k) => (
          <Col xs={24} md={12} lg={8} xl={6} key={k}>
            <motion.div whileHover={{ scale: 1.05 }}>
              <div
                style={{
                  background: `linear-gradient(315deg, ${Color.paleBule},${Color.darkBlue})`,
                  minHeight: "420px",
                  // background: Color.paleBule,
                  borderRadius: 10,
                  padding: 15,
                  border: `1px solid rgb(40,40,40)`,
                  position: "relative",
                  opacity: 0.9
                }}
              >
                <img
                  src={v.imgURL}
                  alt="thumbnail"
                  style={{ width: "100%", aspectRatio:2/1 }}
                />
                <div style={{ minHeight: 140 }}>
                  <div className="title">{v.title}</div>
                  <hr style={{opacity:0.1}}/>
                  <div style={{ color: "gray" }}>{v.description}</div>
                  {v.projectURL&&<div style={{ paddingTop: 10, color: "gray" }}>
                    <strong>Link : </strong>
                    <a href={v.projectURL} target="_blank" style={{textOverflow:"ellipsis"}}>
                      {v.projectURL}
                    </a>
                  </div>}

                </div>
                
                <LanguageBar
                  language={v.languages}
                  showModal={(e) => showModal(k)}
                  screen={v.screen}
                />
              </div>
            </motion.div>
          </Col>
        ))}
      </Row>
    </>
  );
};
export default Project;

const LanguageBar = ({ screen, language, showModal }) => {
  return (
    <Row justify={"space-between"} 
    style={{
      position: "absolute",
      bottom: 20,
      width: "90%"
    }}>
      {screen ? (
        <Button className="btn-primary" onClick={() => showModal()}>
          Preview
        </Button>
      ) : (
        <div style={{ lineHeight: 2, color: "yellow" }}>
          <span>Preview unavailable</span>
        </div>
      )}
      <Space>
        {language.map((v, k) => (
          <div
            key={k}
            style={{
              float: "right",
              width: 20,
              height: 20,
              borderRadius: "100%",
              background: "white",
              textAlign: "center",
              padding: 5,
            }}
            title={v}
          >
            <img src={`./icon/${v}.png`} style={{ width: "100%" }} />
          </div>
        ))}
      </Space>
    </Row>
  );
};
