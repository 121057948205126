import { Button, Col, Divider, Popover, Row, Space } from "antd";
import { motion } from "framer-motion";
import AnimatedCharacters from "../animation/animatedText";
import IMG from "../assets/image/my_image_2.png";
import Rectangle from "../assets/image/rectangle.svg";

const Home = ({ showModal }) => {
  // Placeholder text data, as if from API
  const placeholderText = [
    { type: "heading2", text: "Hello, I am" },
    {
      type: "heading1",
      text: "HEIN HTET",
    },
  ];

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  const _handleContactMe = (e) => {
    showModal();
  };
  return (
    <Row align="middle" style={{ height: "100%" }}>
      <Col span={12} style={{ padding: "5%", zIndex:1 }}>
        <motion.div
          className="App"
          initial="hidden"
          animate="visible"
          variants={container}
        >
          <div>
            {placeholderText.map((item, index) => {
              return <AnimatedCharacters {...item} key={index} />;
            })}
          </div>
        </motion.div>
        <Space direction={"vertical"} size={25}>
          <div className="fade">I am a professional Full-stack Developer.</div>
          <div className="fade">Experienced in Web Design and Development.</div>
          <hr style={{ opacity: 0.3 }} />
          <p className="slide-text">Contact me on</p>
          <p className="slide-icon">
          <Space direction={"horizontal"} size={15}>
            <a
              href="mailto:heinhtet25944@gmail.com"
              target="_blank"
              title="heinhtet25944@gmail.com"
            >
              <img src="./icon/contact/gmail.png" alt="icon" width={40} />
            </a>
            <a
              href="https://www.linkedin.com/in/hein-htet-2a34241a3"
              target="_blank"
            >
              <img src="./icon/contact/linkedin.png" alt="icon" width={40} />
            </a>
            <a href="https://github.com/NewDivide41194" target="_blank">
              <img src="./icon/contact/github.png" alt="icon" width={40} />
            </a>
            <Popover content={"+66 0988477743"} title="Whatsapp Number">
              <img src="./icon/contact/whatsapp.png" alt="icon" width={40} />
            </Popover>
            <Popover content={<img src="./icon/contact/image-43B2-934B-BF-0.jpeg" style={{maxWidth:250}}/>} title="LINE QR">
              <img src="./icon/contact/line.png" alt="icon" width={40} />
            </Popover>
            {/* <Popover content={"+95 770770947"} title="Twitter Number">
              <img src="./icon/contact/twitter.png" alt="icon" width={40} />
            </Popover> */}
            </Space>
          </p>
        </Space>
        <Divider />

        <div className="fade">
          {/* <Button
            size="large"
            onClick={() => _handleContactMe()}
            className="btn-primary"
          >
            Contact Me
          </Button> */}
          <Button size="large" className="btn-primary">
            <a href="/Hein_Htet_CV.pdf" download style={{fontWeight:"lighter"}}>
              <i className="fa fa-download" style={{ paddingRight: 10 }} />
              Download CV
            </a>
          </Button>
        </div>
      </Col>
      <Col className="img-col" span={12} style={{ textAlign: "center", zIndex:0 }}>
        <img
          src={IMG}
          alt="my_image"
          style={{ maxHeight: "85vh", maxWidth: "100%", paddingTop: 20 }}
        />
        <img
          src={Rectangle}
          alt="my_image"
          style={{ maxHeight: "90vh",  paddingTop: 20, position: "absolute", right: 25, zIndex:"-1", top: -270 }}
        />
      </Col>
    </Row>
  );
};
export default Home;
