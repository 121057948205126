import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Particle } from "./animation/particles";
import { NavBar } from "./components/navbar";
import { EducationPage, ExperiencePage, HomePage, ProjectPage, SkillPage } from "./page";

const pages = [
  { element: <HomePage />, path: "/home" },
  { element: <EducationPage />, path: "/education" },
  { element: <SkillPage />, path: "/skills" },
  { element: <ProjectPage />, path: "/projects" }
];

function App() {
  return (
    <Router>
      <NavBar />
      <Particle />

      <Routes>
        {pages.map((v, k) => (
          <Route element={v.element} path={v.path} key={k} />
        ))}
        <Route path="*" element={<Navigate to="/home" replace/>} />
      </Routes>
    </Router>
  );
}

export default App;
