import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Button, Form, Input } from "antd";

export const ContactForm = () => {
  const form = useRef();

  const validateMessages = {
    required: "${label} is required!",

    types: {
      email: "${label} is not a valid email!",
    },
  };
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const sendEmail = (e) => {
    console.log(e);

    e.preventDefault();

    emailjs
      .sendForm(
        "service_fkz52nb",
        "template_zgnqk85",
        e.target,
        "8SgmylhVvilxh51M3"
      )
      .then(
        (result) => {
          console.log("ddd",result);
        },
        (error) => {
          console.log("eee",error.text);
        }
      );
  };

  return (
    <Form
      ref={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      validateMessages={validateMessages}
      onSubmitCapture={sendEmail}
    >
      <Form.Item label="Name" name="name" id="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        id="email"
        rules={[
          {
            required: true,
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Message" id="message" name="message" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          className="btn-primary"
          htmlType="submit"
          // onClick={(e) => sendEmail(e)}
        >
          <span>
            Send
            <i className="fa fa-paper-plane" style={{ paddingLeft: 5 }} />
          </span>
        </Button>
      </Form.Item>
    </Form>
  );
};
