import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";
import { Color } from "../config/config.color";

export const MyProgressBar = ({ percent }) => {
  return (
    <div title={percent + "%"}>
      <ProgressBar
        completed={percent}
        animateOnRender
        height="5px"
        isLabelVisible={false}
        bgColor={Color.lightBlue}
        baseBgColor={Color.darkBlue}
        padding={3}
      />
    </div>
  );
};
